import { useGetGiochiMutation } from "../store/apis/couponApi";
import { store } from "../store/index";
import ListaGiochi from "./Elements/ListaGiochi";
import axios from "axios";
import imgCeramica_it from "../assets/ceramica.png";
import imgArte_it from "../assets/arte.png";
import imgStampa_it from "../assets/stampa.png";
import imgTempo_it from "../assets/tempo.png";
import imgCeramica_en from "../assets/ceramica_en.png";
import imgArte_en from "../assets/arte_en.png";
import imgStampa_en from "../assets/stampa_en.png";
import imgTempo_en from "../assets/tempo_en.png";
import lading from "../assets/loading.gif";
import mascotteTempo from "../assets/Mascotte_tempo.png";
import { useState } from "react";
import { useEffect } from "react";
//import { id } from "date-fns/locale";
import MapComponent from "./Maps/MapComponent";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

function GiochiList(props) {
  const { t, i18nM } = useTranslation();
  const currentLanguage = i18n.language;
  let imgCeramica = imgCeramica_it;
  let imgArte = imgArte_it;
  let imgStampa = imgStampa_it;
  let imgTempo = imgTempo_it;
  if (currentLanguage == "en") {
    imgCeramica = imgCeramica_en;
    imgArte = imgArte_en;
    imgStampa = imgStampa_en;
    imgTempo = imgTempo_en;
  }

  var message = "";
  var chiGioca = [];
  const [couponRedention, result] = useState();
  //creao un setinterval per controllare per leggere periodicamente la variabile eta in sessione
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("ping %o", localStorage.getItem("youWin"));

      if (localStorage.getItem("youWin") == 1) {
        localStorage.setItem("youWin", 0);
        apriLstaGiochi(
          localStorage.getItem("tema"),
          localStorage.getItem("id_gioco_selected")
        );
        getGiochiPosizione();
      }
    }, 500);
    // Pulisci l'intervallo quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [formData, setFormData] = useState({
    nomeGiocatore: "",
  });

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleNameInsert = () => {
    if (formData.nomeGiocatoe == "") {
      window.openModal1("Errore", "Inserisci il nome del giocatore");
      return;
    }
    setForzaCambioGiocatore(false);
    localStorage.setItem(
      "team_" + localStorage.nomeTeamGiocatore,
      formData.nomeGiocatore
    );
    setMessaggioBenvenutoDiv(messaggioBenvenuto());
  };

  const cercaPrePost = (id_gioco) => {
    localStorage.setItem("id_gioco_selected", id_gioco);
  };

  const [forzaCambioGiocatore, setForzaCambioGiocatore] = useState(false);
  // se è settato localStorage.nomeTeamGiocatore fai comparire un messaggio di benvenuto
  const messaggioBenvenuto = () => {
    if (localStorage.getItem("team_" + localStorage.nomeTeamGiocatore)) {
      return (
        <>
          <h2 className="messaggioBenvenuto">
            {t("Benvenuto")}{" "}
            <b>
              {localStorage.getItem("team_" + localStorage.nomeTeamGiocatore)}
            </b>
            !
          </h2>
          <button
            onClick={() => {
              setForzaCambioGiocatore(true);
            }}
          >
            {t("Cambia giocatore")}
          </button>
        </>
      );
    }
  };

  const formChiGioca = () => {
    if (
      !localStorage.getItem("team_" + localStorage.nomeTeamGiocatore) ||
      forzaCambioGiocatore
    ) {
      return (
        <div>
          <h2 className="nomeGiocatore">
            {t("Chi gioca?")}
            <label>{t("Nome")}: </label>
            <input
              type="text"
              name="nomeGiocatore"
              value={formData.nomeGiocatore}
              onChange={handleInputChange}
            />
            <button onClick={handleNameInsert}>{t("Salva")}</button>
          </h2>
        </div>
      );
    }
  };
  const [messaggioBenvenutoDiv, setMessaggioBenvenutoDiv] = useState(
    <> {messaggioBenvenuto()}</>
  );
  const [giochi, setGiochi] = useState([]);



  const apriLstaGiochi = (tema, id_gioco) => {
    localStorage.setItem("youWin", 0);
    if (id_gioco == null) {
      localStorage.setItem("id_gioco_selected", "");
    } else {
      localStorage.setItem("id_gioco_selected", id_gioco);
    }

    if (tema != "") {
      localStorage.setItem("tema", tema);
    } else {
      localStorage.setItem("tema", "TUTTE LE TAPPE");
    }

    if (tema == "TUTTE LE TAPPE") {
      tema = "";
    }

    setGiochi([]);

    const bearer_token = `Bearer ${store.getState().savedToken}`;
    const config = {
      headers: {
        Authorization: bearer_token,
      },
    };
    let formData = {
      idTeamGiocatore: localStorage.getItem("idTeamGiocatore"),
      tema: tema,
      lingua: localStorage.getItem("language"),
    };
    axios
      .post(window.apiUrl + "/giochi", formData, config)
      .then(function (response) {
        console.log(response.data.data);
        //ciclo su response data per ottenere le coordinate dei giochi
        setGiochi(
          ListaGiochi(
            response.data.data,
            props.open,
            props.close,
            props.change,
            props.g_id,
            cercaPrePost,
            t
          )
        );
      })
      .catch(function (error) {
        /* 
                    console.log(error);
                    console.log(error.response.data);
                    console.log(error.response.status); 
                    */
        if (error.response.data.message) {
          window.openModal1("Errore", error.response.data.message);
        } else {
          window.openModal1(
            "Errore",
            "Si è verificato un errore!, riprova più tardi"
          );
        }
      });
    if (result.data) {
      if (result.data.success) {
        message = ListaGiochi(result.data.data);
        //if(result.data.data.length === 0) return (<div>Non ci sono giochi per questa fascia di età</div>

        return (
          <div className="giochi">
            {ListaGiochi(
              result.data.data,
              props.open,
              props.close,
              props.change
            )}
          </div>
        );
      } else {
        return <div className="error">Errore: {result.data.message}</div>; //
      }
    }
  };

  // chima l'api con axios per ottenere la lista dei points sulla mappa http://192.168.1.24:8000/api/giochiPosizione?idTeamGiocatore=2&tema
  // faccio anche il controllo dei giochi completati e non completati
  const getGiochiPosizione = () => {
    const bearer_token = `Bearer ${store.getState().savedToken}`;
    const config = {
      headers: {
        Authorization: bearer_token,
      },
    };
    let formData = {
      idTeamGiocatore: localStorage.getItem("idTeamGiocatore"),
      tema: "",
      lingua: localStorage.getItem("language"),
    };

    axios
      .post(window.apiUrl + `/giochiPosizione`, formData, config)
      .then(function (response) {
        let points = response.data.data;
        let concluso = true;
        points.forEach((item) => {
          if (item.ended_at != null) {
            item.backgroundColor = "#333";
            item.ended = true;
          } else {
            concluso = false;
            item.backgroundColor = "#01557";
            item.ended = false;
          }
        });
        if (concluso) {
          window.openModal2(
            "Complimenti",
            "Hai completato tutti i giochi, ora puoi ritirare il tuo premio",
            "success"
          );
          /* */
        }else{
          setPoints(points);
        }
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [hrefToPoint, hrefToPointSet] = useState("");

  const [key1, setKey1] = useState(0);
  //gestione del click su marker
  const handleMarkerClick = (point) => {
    //<img src={mappa} className='mapIcon'  />
    window.scrollTo(0, 230);
    hrefToPointSet(() => {
      return (
        <div className="nomeGiocatore">
          <img src={lading} className="mapIcon" style={{ width: "30px" }} />
        </div>
      );
    });

    setTimeout(() => {
      hrefToPointSet(() => {
        return (
          <div className="nomeGiocatore">
            <h5>
              {t("Apri Maps per")}: <b>{point.tappa}</b>
            </h5>
            <button
              onClick={() => {
                window.open(
                  "https://www.google.com/maps?q=" +
                    point.lat +
                    "," +
                    point.long,
                  "_blank"
                );
              }}
            >
              {t("Vai")}
            </button>
            <h5>
              {t("Apri direttamente il gioco")}: <b>{point.titolo}</b>
            </h5>
            <button
              onClick={() => {
                if (point.ended) {
                  window.openModal1(
                    "Attenzione",
                    "Il gioco è stato già completato"
                  );
                  return;
                } else {
                  props.change(
                    point.url,
                    point.id_hash,
                    point.popup,
                    point.id_gioco
                  );
                  window.blockWindowBox();
                  setKey1(key1 + 1);
                }
              }}
            >
              {t("Apri")}
            </button>
          </div>
        );
      });
    }, 1000);
  };

  const [points, setPoints] = useState([]);
  if (points.length == 0) {
    getGiochiPosizione();
  }

  return (
    <div className="homeContainer">
      <div className="row">
        <h1>Team: {localStorage.nomeTeamGiocatore}</h1>
      </div>
      {messaggioBenvenutoDiv}
      <div className="quoVadis">
        {formChiGioca()}
        <img
          src={mascotteTempo}
          alt="Mascotte Ceramica"
          className="mascotteCentrale"
        />
        {hrefToPoint}
      </div>

      {points.length > 0 && (
        <MapComponent
          points={points}
          onMarkerClick={handleMarkerClick}
          key1={key1}
        />
      )}
      {giochi}

      <button
        className="titoloCentraleGiochi"
        onClick={() => apriLstaGiochi("")}
      >
        {t("TUTTE LE TAPPE")}
      </button>
      <div className="imgSceltaTemabox">
        <img
          src={imgTempo}
          className="imgSceltaTema"
          onClick={() => apriLstaGiochi("tempo")}
          alt="Mascotte Ceramica"
        />
        <img
          src={imgCeramica}
          className="imgSceltaTema"
          onClick={() => apriLstaGiochi("ceramica")}
          alt="Mascotte Ceramica"
        />
        <img
          src={imgArte}
          className="imgSceltaTema"
          onClick={() => apriLstaGiochi("arte")}
          alt="Mascotte Ceramica"
        />
        <img
          src={imgStampa}
          className="imgSceltaTema"
          onClick={() => apriLstaGiochi("stampa")}
          alt="Mascotte Ceramica"
        />
      </div>

      <div className="row dati">
        <ul>
          <li>
            <strong>Email:</strong> {localStorage.getItem("email")}
          </li>
          <li>
            <strong>Coupon:</strong> {localStorage.getItem("coupon")}
          </li>
        </ul>
      </div>
    </div>
  );
}
export default GiochiList;

import { useCouponRedentionMutation } from "../store/apis/couponApi";
import { store }  from '../store/index';
import React, { useState } from 'react';
//import imgMascotte from '../assets/Mascotte_tempo.png';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function RiscattaCoupon() {
    const { t } = useTranslation();
    const token = localStorage.getItem('token');

    const inviaCouponLoggato = async () => {
        window.loading(true);
           try {
            const bearer_token = `Bearer ${store.getState().savedToken}`;
            const config = {
                headers: {
                    Authorization: bearer_token
                }
            };
            var formDataInvio = formData;
                if(localStorage.getItem('id_utente') !== null) {
                    formDataInvio['id_utente'] = localStorage.getItem('id_utente');
                }else{
                    alert("id_utente non presente");
                }
                
            const result = await axios.post(window.apiUrl+'/registraCouponLogged', formDataInvio, config)
                // La chiamata è andata a buon fine, puoi gestire la risposta qui
              
            if ('message' in result.data) {
                if('data' in result.data) {
                    
                    localStorage.setItem('user', result.data.data.name);
                    localStorage.setItem('coupon', result.data.data.coupon);
                    localStorage.setItem('eta', result.data.data.eta);
                    localStorage.setItem('id_utente', result.data.data.id);
                    window.location.replace("/coupons");
                }else{
                    console.log("nessun data nella risposta");
                }
            }else{
                console.log("nessun messaggio nella risposta");
            }
            
        } catch (error) {
             window.loading(false);
             alert(error);
            // Gestisci l'errore in modo appropriato
            // estraggo il response
            let message = 'Errore generico nella registrazione del coupon 02';
            if(error.response) {
                message = error.response.data.message;
            }
            window.openModal1("Errore invio coupon", message)
        } finally{
            window.loading(false);
            console.log("finally, loading false");
        }

    }

    const iviaCoupon = async () => {

         //controllo che sia inserito il ccodice del coupon
        if(formData.coupon === '') {
            window.openModal1("Errore invio coupon", "Inserire il codice del coupon")
            return;
        }   


        window.loading(true);
        try {
            const result = await axios.post(window.apiUrl+'/registraCoupon', formData);
                // La chiamata è andata a buon fine, puoi gestire la risposta qui
            console.log(result.data);
            if ('message' in result.data) {
                if('data' in result.data) {
                    if('token' in result.data.data) {

                        store.dispatch({ type: 'SET_TOKEN', payload: result.data.data.token });
                        store.dispatch({ type: 'SET_USER', payload: result.data.data.name });
                        store.dispatch({ type: 'SET_EMAIL', payload: result.data.data.email });
                        localStorage.setItem('token', result.data.data.token);
                        localStorage.setItem('user', result.data.data.name);
                        localStorage.setItem('email', result.data.data.email);
                        localStorage.setItem('coupon', result.data.data.coupon);
                        localStorage.setItem('eta', result.data.data.eta);
                        localStorage.setItem('id_utente', result.data.data.id);
                        window.location.replace("/team");
                    }
                }
            }

        } catch (error) {
             window.loading(false);
            // Gestisci l'errore in modo appropriato
            // estraggo il response
            let message = 'Errore generico nella registrazione del coupon 03';
            if(error.response) {
                message = error.response.data.message;
            }
            
            console.error('Error:', message);
            window.openModal1("Errore invio coupon", message)
        } finally{
            window.loading(false);
            console.log("finally, loading false");
        }
    };

    

   
    const [couponRedention, result] = useCouponRedentionMutation();
    const [localError, setLocalError] = useState('');
    const handleCouponRedention = () => {
        if(privacyChecked === false) {
             window.openModal1("Errore invio coupon", "Occorre accettare il trattamento dei dati ")
            return;
        }else{
            if(token) {

                setFormData({ ...formData, 'id_utente': localStorage.getItem('id_utente') });
                inviaCouponLoggato();
            }
            else{
                iviaCoupon();
            }
            
        }

    }

    const [privacyChecked, setPrivacyChecked] = useState(false);
    const handlePrivacyChange = (event) => {
        setPrivacyChecked(event.target.checked);
    };
        const [isActive, setActive] = useState(true);
        const ToggleClass = () => {
            setActive(!isActive); 
        };
    if(result.data){
        if('success' in result.data) {
            if(result.data.success === false) {
               // setLocalError(result.data.message);
            }
        }
        if ('message' in result.data) {
            if('data' in result.data) {
                if('token' in result.data.data) {
                  console.log("mutationData 002 %o", result.data.data);
                    store.dispatch({ type: 'SET_TOKEN', payload: result.data.data.token });
                    store.dispatch({ type: 'SET_USER', payload: result.data.data.name });
                    store.dispatch({ type: 'SET_EMAIL', payload: result.data.data.email });
                    localStorage.setItem('token', result.data.data.token);
                    localStorage.setItem('user', result.data.data.name);
                    localStorage.setItem('email', result.data.data.email);
                    localStorage.setItem('coupon', result.data.data.coupon);
                    localStorage.setItem('eta', result.data.data.eta);
                    localStorage.setItem('id_utente', result.data.data.id);
                    window.location.replace("/team");
                }
            }
        }
    }
   
    let message = '';   

    /*  GESTISCO IL FORM    */
    const [formData, setFormData] = useState({
            codice: '',
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            eta: '',
            tipo_utente: '1',
            name_team: '',
            name_team02: '',
        });
    
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        if(event.target.name === 'tipo_utente') {
            if(event.target.value === '1') {
                setLabelTipoUtente({labelTipoUtente: t('Famiglia')});
            }else{
                setLabelTipoUtente({labelTipoUtente: t('Scuola')});
            }
        }
    };

    const [labelTipoUtente, setLabelTipoUtente] = useState({
        labelTipoUtente: 'Famiglia'
    })
    const handleSubmit = (event) => {
        event.preventDefault();

    };

    return (
        <>
            <h1  onClick={ToggleClass} className="titoloCentrale">{t("RISCATTA IL COUPON")}</h1>  
            <div className={isActive ? "active boxRiscattaCoupon homeChoise" : "hidden boxRiscattaCoupon homeChoise"} >
                <form onSubmit={handleSubmit} >
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md error">{localError}</div>
                            <div className="col-md formCoupon">          
                                <label className="vip">{t("Inserisci qui il Coupon")}: </label><input type="text" name="coupon" value={formData.copupon} onChange={handleInputChange} /><br />
                                <br />
                                <label>{t('Tipo di giocatore')}</label>
                                <select id="tipo_utente" name="tipo_utente" value={formData.tipo_utente} onChange={handleInputChange}>
                                    <option value="1">{t('Famiglia')}</option>
                                    <option value="2">{t('Scuola')}</option>
                                </select>
                                <br/>
                                <label>{t(labelTipoUtente.labelTipoUtente)}: [{t("opzionale")}] </label><input type="text" name="name" value={formData.name} onChange={handleInputChange} />  
                                {token ? (
                                    // Div da mostrare se il token è presente
                                    <div>
                                        <h2>{t("Sei già loggato come")} {localStorage.getItem('email')}</h2>
                                    </div>
                                ) : (
                                    // Div da mostrare se il token non è presente
                                    <div>
                                <label>Email: </label><input type="email" name="email" value={formData.email} onChange={handleInputChange} />
                                <br />
                                <label>Password: </label><input type="password" name="password" value={formData.password} onChange={handleInputChange} />
                                <br />
                                <label>{t("Conferma Password")}: </label><input type="password" name="password_confirmation" value={formData.password_confirmation} onChange={handleInputChange} />
                                <hr />
                                <br/>
                                <br/>

                                    </div>
                                )}

                                
                                <label>{t("Trattamento dei dati")}, <a href="/privacy" target="_blank">{t("leggi come gestiamo i tuoi dati")}</a></label>
                                <label>
                                    <input
                                    type="checkbox"
                                    checked={privacyChecked}
                                    onChange={handlePrivacyChange }
                                    />  {t("Accetto il trattamento dei dati personali.")}
                                </label>
                                <br/><br/>
                                <h2>{t("Puoi creare 2 team! per personalizzare la difficoltà.")} </h2>
                                <label>{t("Nome del team 1")}: </label><input type="text" name="name_team" value={formData.name_team} onChange={handleInputChange} />
                                <br />
                                <label>{t("Difficoltà")}: </label> 
                                <select name="eta"  onChange={handleInputChange}>
                                    <option value="">{t("Scegli la difficoltà")}</option>
                                    <option value="6-8">{t("Bassa")}</option>
                                    <option value="9-12">{t("Media")}</option>
                                </select>    
                                <br /><br />                                
                                <br /><br />
                                <label> {t("Nome del team 2")}: [{t("opzionale")}]</label><input type="text" name="name_team02" value={formData.name_team02} onChange={handleInputChange} />
                                <br />
                                <label>{t("Difficoltà")}: </label> 
                                <select name="eta2"  onChange={handleInputChange}>
                                    <option value="">{t("Scegli la difficoltà")}</option>
                                    <option value="6-8">{t("Bassa")}</option>
                                    <option value="9-12">{t("Media")}</option>
                                </select>    
                                <br /><br />
                                <button onClick={handleCouponRedention}>{t("Registrati e inizia a giocare")}</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                </form>
            </div>
        </>
    )
} 
export default RiscattaCoupon;